import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, APP_ID, PLATFORM_ID } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { AppComponent } from './app.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApproveComponent } from './components/approve/approve.component';
import { RejectComponent } from './components/reject/reject.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RequiredApprovalQueryService } from './services/required-query.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApprovalService } from './services/approval.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { ApprovalEffects } from './redux/effects';
import { reducers } from 'src/models/redux';
import { RetryinvoiceComponent } from './components/retryinvoice/retryinvoice.component';
import { environment } from '../environments/environment';
import { BulkapprovalComponent } from './components/bulkapproval/bulkapproval.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RejectiondialogComponent as RejectionDialogComponent } from './shared/dialogs/rejectiondialog/rejectiondialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [
    AppComponent,
    ApproveComponent,
    RejectComponent,
    NotFoundComponent,
    SaveButtonComponent,
    RetryinvoiceComponent,
    BulkapprovalComponent,
    RejectionDialogComponent
  ],
  entryComponents: [
    RejectionDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([
      ApprovalEffects
    ]),
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
    NgxSkeletonLoaderModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'cartwheel-approval' }),
    AppRoutingModule
  ],
  providers: [
    RequiredApprovalQueryService,
    ApprovalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
  }
}
