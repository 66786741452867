import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApproveComponent } from './components/approve/approve.component';
import { RejectComponent } from './components/reject/reject.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RequiredApprovalQueryService } from './services/required-query.service';
import { RetryinvoiceComponent } from './components/retryinvoice/retryinvoice.component';
import { BulkapprovalComponent } from './components/bulkapproval/bulkapproval.component';
import { RequiredBulkQueryService } from './services/required-bulk-query.service';

const routes: Routes = [
  {
    path: '', redirectTo: 'NotFound', pathMatch: 'full'
  },
  // {
  //   path: 'Approve', component: ApproveComponent,
  //   canActivate: [RequiredApprovalQueryService]
  // },
  // {
  //   path: 'Reject', component: RejectComponent,
  //   canActivate: [RequiredApprovalQueryService]
  // },
  {
    path: 'RetryInvoice', component: RetryinvoiceComponent
  },
  {
    path: 'Approvals', component: BulkapprovalComponent,
    canActivate:[RequiredBulkQueryService]
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
