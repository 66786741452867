import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RetryInvoice } from 'src/models/RetryInvoice';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  baseUrl = 'api/Invoice';
  constructor(private httpClient: HttpClient) { }

  public RetryInvoiceGeneration(invoiceInfo: RetryInvoice): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${environment.baseUrl}/${this.baseUrl}/RetryInvoiceGeneration`,
      JSON.stringify(invoiceInfo)
    );
  }
}
