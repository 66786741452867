import { SuccessStatus } from '../app/shared/enums';
import { ActionReducerMap } from '@ngrx/store';
import { ApprovalReducer } from '../app/redux/reducers';

export interface AppState {
  approval: ApprovalState; 
}

export interface ApprovalState {
  loading: SuccessStatus;
  error: String;
}

export const reducers: ActionReducerMap<AppState> = {
  approval: ApprovalReducer
};
