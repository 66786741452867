import { Component, OnInit } from '@angular/core';
import { SuccessStatus } from '../../../app/shared/enums';
import { InvoiceService } from 'src/app/services/invoice.service';
import { RetryInvoice } from 'src/models/RetryInvoice';
import { ActivatedRoute } from '@angular/router';
import { MapRetryInvoiceQuery } from 'src/functions/misc';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-retryinvoice',
  templateUrl: './retryinvoice.component.html',
  styleUrls: ['./retryinvoice.component.scss']
})
export class RetryinvoiceComponent implements OnInit {
  public status = SuccessStatus;
  public loading = SuccessStatus.IsLoading;
  public invoiceInfo: RetryInvoice;
  constructor(private invoiceService: InvoiceService,
    private location: Location,
    private title: Title,
    private ar: ActivatedRoute, ) { }

  ngOnInit() {
    this.ar.queryParams.subscribe(params => {
      this.invoiceInfo = MapRetryInvoiceQuery(params);
      if (this.invoiceInfo.isValid) {
        this.invoiceService.RetryInvoiceGeneration(this.invoiceInfo).pipe(
          tap(
            data => this.loading = SuccessStatus.Success,
            error => {
              this.loading = SuccessStatus.Error;
            }
          )
        ).subscribe(res => res);
      } else {
        this.loading = SuccessStatus.Error
      }
      this.location.replaceState('RetryInvoice');
      this.title.setTitle(`Cartwheel: Retrying to generate your invoice...`);
    });
  }

}
