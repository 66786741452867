import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let newReq;
    if (!req.headers.has('content-type') || !req.headers.has('Content-Type')) {
      newReq = req.clone({
        headers: req.headers.set('content-type', 'application/json')
      });
    }
    return next.handle(newReq || req);
  }
  constructor() { }
}
