import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { takeWhile, catchError } from 'rxjs/operators';
import { ApprovalService } from 'src/app/services/approval.service';
import { ReportService } from 'src/app/services/report.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RejectiondialogComponent } from 'src/app/shared/dialogs/rejectiondialog/rejectiondialog.component';
import { ApprovalStatus } from 'src/app/shared/enums';
import { MapBulkApprovalQuery } from 'src/functions/misc';
import { ApprovalMessage, BulkApprovalRow, EntryRequest } from 'src/models/BulkApprovalVM';
import * as fileSaver from 'file-saver';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bulkapproval',
  templateUrl: './bulkapproval.component.html',
  styleUrls: ['./bulkapproval.component.scss']
})
export class BulkapprovalComponent implements OnInit, OnDestroy {
  takeObservable: boolean = true;
  isMobile$: Observable<boolean>;
  isMobile: boolean;
  allHandled: boolean;
  hasError: boolean = false;
  error: any;
  approvalComplete = false;

  constructor(private dialog: MatDialog,
    private ar: ActivatedRoute,
    private approvalService: ApprovalService,
    private location: Location,
    private _snackBar: MatSnackBar,
    private title: Title,
    private reportService: ReportService,
    private responsiveService: ResponsiveService) { }
  public pageLoaded = false;
  public fullColumns = ['select', 'username', 'hoursWorked', 'approval', 'rejection', 'downloadReport'];
  public mobileColumns = ['username', 'hoursWorked', 'approval', 'rejection'];
  public columnsWithoutReport = ['select', 'username', 'hoursWorked', 'approval', 'rejection', 'downloadReport'];
  public selectedColumns: string[];
  public dataSource: MatTableDataSource<BulkApprovalRow>;
  public allSelected = false;
  multiSelected: boolean = false;
  public data: ApprovalMessage = <ApprovalMessage>{
    approvals: this.createBlankApprovals()
  };

  private requestData: EntryRequest;





  public selection = new SelectionModel<BulkApprovalRow>(true);

  public rejectedData: BulkApprovalRow[] = new Array<BulkApprovalRow>();
  public approvedData: BulkApprovalRow[] = new Array<BulkApprovalRow>();
  public parsedData: BulkApprovalRow[] = new Array<BulkApprovalRow>();
  public disabledButtons: boolean[] = [];

  private snackBarConfig: MatSnackBarConfig = {
    panelClass: ['cartwheel-snackbar-error'],
    horizontalPosition: 'right',
    verticalPosition: 'top',
    duration: 0,
  }

  ngOnInit() {
    this.isMobile$ = this.responsiveService.getMobileStatus();
    this.isMobile$
      .pipe(takeWhile(() => this.takeObservable))
      .subscribe(mobile => {
        if (mobile) {
          this.selectedColumns = this.mobileColumns;
        } else {
          this.selectedColumns = this.fullColumns;
        }
        this.isMobile = mobile;
        this.generateSubTables();
      });

    this.selection.changed.pipe(
      takeWhile(_ => this.takeObservable)
    ).subscribe((res) => {
      this.allSelected = this.isAllSelected();
      this.multiSelected = this.isMultipleSelected();
    });

    this.ar.queryParams.subscribe(params => {
      this.requestData = MapBulkApprovalQuery(params);
      this.location.replaceState("Approvals");
      this.approvalService.retrieveApprovalInfo(this.requestData)
        .pipe(
          catchError(err => {
            this.hasError = true;
            this.error = err.error;
            return of(err);
          })
        )
        .subscribe(res => {
          this.data = res;
          this.title.setTitle(
            `Cartwheel: Handling approvals for ${this.data.clientName}`
          );
          this.generateSubTables();
        });

    });
  }

  ngOnDestroy() {
    this.takeObservable = false;
  }

  public approve(data: BulkApprovalRow | BulkApprovalRow[]) {
    if (!(data instanceof Array)) {
      data.approvalStatus = ApprovalStatus.Approved;
    } else {
      this.updateStatuses(data, ApprovalStatus.Approved);
    }
    this.generateSubTables();
  }

  public masterToggle() {
    this.allSelected ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public downloadreport(userRow: BulkApprovalRow, index: number): void {
    this.disabledButtons[index] = true;
    const snackBarRef = this._snackBar.open(`Downloading report for ${userRow.username}`, null, this.snackBarConfig);
    this.reportService.getApprovalReport(userRow, this.data, this.requestData.ApproverId)
      .subscribe(res => {
        this.disabledButtons[index] = false;
        snackBarRef.dismiss();
        const file = res;
        const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/['"]+/g, '')
        fileSaver.saveAs(file.body, filename);
      },
        err => {
          userRow = Object.assign({}, userRow, <BulkApprovalRow>{
            isLoading: false
          });
        });
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numRows === numSelected;
  }

  public isMultipleSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 1;
  }

  public submitApproval() {
    this.pageLoaded = false;
    this.approvalService.HandleApprovals(<ApprovalMessage>{
      approvals: this.rejectedData.concat(this.approvedData),
      startDate: this.data.startDate,
      endDate: this.data.endDate,
      clientName: this.data.clientName
    }, this.requestData.ApproverId,
      this.requestData.clientId).subscribe(res => this.approvalComplete = true);
  }

  private updateStatuses(data: BulkApprovalRow[], status: ApprovalStatus,
    rejectionReason?: string) {
    if (status === ApprovalStatus.Approved) {
      for (let i = 0; i < data.length; i++) {
        data[i] = Object.assign(<BulkApprovalRow>{}, data[i], {
          approvalStatus: status
        });
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        data[i] = Object.assign(<BulkApprovalRow>{}, data[i], {
          rejectionReason: rejectionReason,
          approvalStatus: ApprovalStatus.Rejected
        });
      }
    }
  }

  public reject(data: BulkApprovalRow | BulkApprovalRow[]) {
    const dialogRef = this.dialog.open(RejectiondialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result && !(data instanceof Array)) {
        data.approvalStatus = ApprovalStatus.Rejected;
        data.rejectionReason = result;
      } else if (result && data instanceof Array) {
        this.updateStatuses(data, ApprovalStatus.Rejected, result);
      }
      this.generateSubTables();

    });
  }

  generateSubTables() {
    if (!this.isMobile && !this.data.approverHasReportSelected) {
      this.selectedColumns = this.columnsWithoutReport;
    }
    this.parsedData = this.data?.approvals?.filter(e => e.approvalStatus === null);
    this.approvedData = this.data?.approvals?.filter(e => e.approvalStatus === ApprovalStatus.Approved);
    this.rejectedData = this.data?.approvals?.filter(e => e.approvalStatus === ApprovalStatus.Rejected);

    this.disabledButtons = [];
    for (let i = 0; i < this.parsedData.length; i++) {
      this.disabledButtons[i] = false;
    }

    this.dataSource = new MatTableDataSource(this.parsedData);
    this.allHandled = this.allApprovalsHandled();

  }

  private createBlankApprovals(): BulkApprovalRow[] {
    return [<BulkApprovalRow>{
      username: '',
      approvalStatus: null,
      hoursWorked: 0,
      rejectionReason: ''
    }, <BulkApprovalRow>{
      username: '',
      approvalStatus: null,
      hoursWorked: 0,
      rejectionReason: ''
    }, <BulkApprovalRow>{
      username: '',
      approvalStatus: null,
      hoursWorked: 0,
      rejectionReason: ''
    }];
  }

  private allApprovalsHandled(): boolean {
    if (this.approvedData?.length + this.rejectedData?.length == this.data?.approvals?.length) {
      return true;
    }
    return false;
  }

  public closeWindow() {
    window.close();
  }

}
