<div class="card">
  <div class="row first">
    <div>
      <!-- <p>{{approvalData.clientName}}</p> -->
    </div>
    <div>
      <!-- <p>{{startDate}} - {{endDate}}</p> -->
    </div>
  </div>
  <div class="row second">
    <div *ngIf="loading === status.IsLoading">
      <p>Please wait while we schedule your invoice to be generated...</p>
      <mat-spinner single-color strokeWidth="4" diameter="50">
      </mat-spinner>
    </div>
    <p *ngIf="loading === status.Success">We're resending your invoice! Feel free to close this window</p>
    <p *ngIf="loading === status.Error">There was an error retrying your invoice</p>
  </div>
</div>
