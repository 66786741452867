export enum SuccessStatus {
    IsLoading,
    Success,
    Error,
    Disabled,
    Enabled
  }

  export enum ApprovalStatus{
    Approved,
    Rejected
  }


  export enum ScreenSize {
    Small = 'sm',
    Medium = 'md',
    Large = 'lg'
  }