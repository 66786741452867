import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, of, pipe } from "rxjs";
import { Approval } from "src/models/Approvals";
import { ApprovalMessage, BulkApprovalRequest, EntryRequest } from "src/models/BulkApprovalVM";
import { ApprovalStatus } from "../shared/enums";
@Injectable({
  providedIn: "root"
})
export class ApprovalService {
  readonly baseUrl: string = "api/Approval";

  constructor(private httpClient: HttpClient) { }

  public ApproveTimesheet(approval: Approval): Observable<Approval> {
    return this.httpClient.post<Approval>(
      `${environment.baseUrl}/${this.baseUrl}/ApproveTimesheet`,
      JSON.stringify(approval)
    );
  }

  public RejectTimesheet(approval: Approval, rejectionReason: string) {
    return this.httpClient.post<Approval>(
      `${environment.baseUrl}/${this.baseUrl
      }/RejectTimesheet?rejectionReason=${rejectionReason}`,
      JSON.stringify(approval)
    );
  }

  public retrieveApprovalInfo(request: EntryRequest) {
    return this.httpClient.post<ApprovalMessage>(
      `${environment.baseUrl}/${this.baseUrl}/RetrieveMultipleApprovals`,
      JSON.stringify(request)
    );
  }

  public RetrieveApprovalInformation(): Observable<ApprovalMessage> {
    return this.dummyData();
  }

  public HandleApprovals(msg: ApprovalMessage,
    approverId: string,
    clientId: string) {
    const rejected = msg.approvals.filter(
      e => e.approvalStatus === ApprovalStatus.Rejected)
      .map(
        e => [e.approvalSagaId, e.rejectionReason]);

    const approved = msg.approvals
      .filter(e => e.approvalStatus === ApprovalStatus.Approved)
      .map(e => e.approvalSagaId);

    const bulkRequest = <BulkApprovalRequest>{
      rejected,
      approved,
      startDate: msg.startDate,
      endDate: msg.endDate,
      approverId,
      clientId
    };

    return this.httpClient.post(`${environment.baseUrl}/${this.baseUrl}/BulkTimesheet`,
      JSON.stringify(bulkRequest));
  }

  private dummyData(): Observable<ApprovalMessage> {
    const newDate = new Date();
    const results = {
      clientName: 'Dr.Catalyst',
      startDate: newDate,
      approverHasReportSelected: true,
      endDate: new Date(new Date().setDate(newDate.getDate() + 7)),
      approvals: [{
        username: 'Stephen Wigginton',
        approvalSagaId: '',
        userId: 'asdf',
        hoursWorked: 32,
        approvalStatus: null
      }, {
        username: 'Donald Duck',
        userId: 'asdf',
        approvalSagaId: '',
        hoursWorked: 55,
        approvalStatus: null
      },
      {
        username: 'Son Goku',
        userId: 'asdf',
        approvalSagaId: '',
        hoursWorked: 33,
        approvalStatus: null
      },
      {
        username: 'Uzimaki Naruto',
        userId: 'asdf',
        approvalSagaId: '',
        hoursWorked: 31,
        approvalStatus: null
      }]
    };
    return of(results);

  }
}
