import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalMessage, BulkApprovalRow } from 'src/models/BulkApprovalVM';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  public getApprovalReport(reportRow: BulkApprovalRow, msg: ApprovalMessage, approverId: string) {
    const results = new ApprovalReportRequest(
      new Date(msg.startDate),
      new Date(msg.endDate),
      reportRow.userId,
      approverId
    );

    return this.http.post(`${environment.baseUrl}/api/report/RetrieveReport`, JSON.stringify(results),
      {
        responseType: 'blob',
        observe: 'response'
      });
  }
}

class ApprovalReportRequest {
  startDate: string;
  endDate: string;
  userId: string;
  approverId: string;
  constructor(
    startDate:Date,
    endDate:Date,
    userId:string,
    approverId:string){
      this.endDate = endDate.toJSON();
      this.startDate = startDate.toJSON();
      this.userId = userId;
      this.approverId = approverId;
    }
}
