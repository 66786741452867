import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScreenSize } from '../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  private mobileWidth = 768;
  private mediumWidth = 992;
  private isMobile: BehaviorSubject<boolean>;
  private screenSize: BehaviorSubject<ScreenSize>;

  constructor() {
    this.isMobile = new BehaviorSubject<boolean>(window.innerWidth <= this.mobileWidth);

    const screenSize =
      (window.innerWidth <= this.mobileWidth) ?
        ScreenSize.Small :
      (window.innerWidth > this.mediumWidth) ?
        ScreenSize.Large :
      ScreenSize.Medium

    this.screenSize = new BehaviorSubject<ScreenSize>(screenSize);
  }

  getMobileStatus(): Observable<boolean> {
    return this.isMobile.asObservable();
  }

  getScreenSize(): Observable<ScreenSize> {
    return this.screenSize.asObservable();
  }

  public checkWidth(): void {
    const width = window.innerWidth;
    if (width <= this.mobileWidth) {
      this.screenSize.next(ScreenSize.Small);
      this.isMobile.next(true);
    } else if (width > this.mediumWidth) {
      this.screenSize.next(ScreenSize.Large);
      this.isMobile.next(false);
    } else {
      this.screenSize.next(ScreenSize.Medium);
      this.isMobile.next(false);
    }
  }
  public checkMobileHeight(): number {
    const height = window.innerHeight;
    return height;
  }
}
