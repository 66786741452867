import { Actions, ActionTypes } from './actions';
import { createSelector } from 'reselect';
import { SuccessStatus } from '../shared/enums';
import { ApprovalState, AppState } from '../../models/redux';

export const initialState: ApprovalState = {
    loading: null,
    error: null
};

export function ApprovalReducer(state = initialState, action: Actions): ApprovalState {

    switch (action.type) {
        case ActionTypes.APPROVE: {
            return Object.assign({}, state, {
                loading: SuccessStatus.IsLoading
            });
        }
        
        case ActionTypes.REJECT: {
            return Object.assign({}, state, {
                loading: SuccessStatus.IsLoading
            });
        }

        case ActionTypes.APPROVESUCCESSFUL:
        case ActionTypes.REJECTSUCCESSFUL: {
            return Object.assign({}, state, {
                loading: SuccessStatus.Success
            });
        }

        case ActionTypes.APPROVEFAILURE:
        case ActionTypes.REJECTFAILURE: {
            return Object.assign({}, state, {
                loading: SuccessStatus.Error,
                error: action.payload.error
            });
        }

    default:
        return state;
  }
}

export const getLoading = createSelector((state: AppState) => state.approval, (state: ApprovalState) => state.loading);
export const getError = createSelector((state: AppState) => state.approval, (state: ApprovalState) => state.error);