import { Approval, Approver } from "../models/Approvals";
import { RetryInvoice } from "src/models/RetryInvoice";
import { EntryRequest } from "src/models/BulkApprovalVM";

export function ValidateApprovalQuery(route: {
  [key: string]: any;
}): boolean {
  const clientName = route["clientName"];
  const startDate = route["startDate"];
  const endDate = route["endDate"];
  const firstName = route["firstName"];
  const lastName = route["lastName"];
  const code = route["code"];
  const approverId = route["approverId"];
  const clientId = route["clientId"];
  if (!code || !lastName || !firstName || !endDate || !startDate || !clientName || !approverId || !clientId) {
    return false;
  }
  return true;
}

export function ValidateBulkQuery(route: { [key: string]: any }){
  const clientId = route['id'];
  const approverId = route['approverId'];
  const startDate = route['startDate'];
  const endDate = route['endDate'];
  if(!clientId || !approverId || !startDate || !endDate){
    return false;
  }
  return true;
}

export function MapApprovalQuery(route: {
  [key: string]: any;
}): Approval {
  const approval = new Approval();
  approval.clientName = unescape(route["clientName"]);
  approval.code = route["code"];
  approval.endDate = new Date(unescape(route["endDate"]).split(' ').slice(0, 2).join(' '));
  approval.startDate = new Date(unescape(route["startDate"]).split(' ').slice(0, 2).join(' '));
  approval.firstName = route["firstName"];
  approval.lastName = route["lastName"];
  approval.specificApprover = route["emailAddress"];
  approval.approver = new Approver(route['clientId'], route['approverId']);
  approval.approvalDateTime = new Date();

  return approval;
}

export function MapBulkApprovalQuery(route: {
  [key: string]: any;
}): EntryRequest {
  const clientId = route['id'];
  const approverId = route['approverId'];
  const startDate = new Date(unescape(route["startDate"]).split(' ').slice(0, 2).join(' '));
  const endDate = new Date(unescape(route["endDate"]).split(' ').slice(0, 2).join(' '));
  if (!clientId || !approverId || !startDate || !endDate) {
    return null;
  }
  return <EntryRequest>{
    clientId,
    ApproverId: approverId,
    startDate,
    endDate
  };
}

export function MapRetryInvoiceQuery(route: { [key: string]: any }): RetryInvoice {
  const retry = new RetryInvoice();
  retry.invoiceSagaId = route["InvoiceSagaId"];
  retry.uniqueInvoiceId = route["UniqueInvoiceId"];
  if (retry.invoiceSagaId === null || retry.uniqueInvoiceId === undefined) {
    retry.isValid = false;
  }
  return retry;
}
