<h1 mat-dialog-title>
    Please enter your rejection reason
</h1>
<h2 *ngIf="rejectionList">
    The rejection reason you select will be used for all selected timesheets
</h2>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Rejection reason?</mat-label>
        <textarea matInput [(ngModel)]="rejectionReason"
            placeholder="Ex. This user billed hours that doesn't make sense..."></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel rejection</button>
    <button mat-button [mat-dialog-close]="rejectionReason" [disabled]="!rejectionReason">Reject</button>
</div>