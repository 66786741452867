import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ValidateApprovalQuery } from '../../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class RequiredApprovalQueryService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!ValidateApprovalQuery(route.queryParams)) {
      return of(false);
    }
    return of(true);
  }
  constructor(private router: Router) { }
}
