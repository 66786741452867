import { Component } from '@angular/core';
import { ResponsiveService } from './services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  onResize() {
    this.responsiveService.checkWidth();
  }

  constructor(private responsiveService:ResponsiveService){}
}
