<div *ngIf="!hasError && !approvalComplete" class="content">
  <div class="header">
    <div class="populatedHeader" *ngIf="data?.clientName">
      <h1 *ngIf="!isMobile">Approval for client {{data?.clientName}}</h1>
      <h2 *ngIf="!isMobile">Week of {{data?.startDate | date:'shortDate'}} to {{data?.endDate |
        date:'shortDate'}}</h2>
      <h2 *ngIf="isMobile">Approval for client {{data?.clientName}}</h2>
      <h3 *ngIf="isMobile">
        Week of {{data?.startDate | date:'shortDate'}} to {{data?.endDate |
        date:'shortDate'}}
      </h3>
    </div>
    <div class="unpopulatedHeader" *ngIf="!data?.clientName">
      <h1>
        <ngx-skeleton-loader count="1" animation="pulse"></ngx-skeleton-loader>
      </h1>
      <h2>
        <ngx-skeleton-loader count="1" animation="pulse"></ngx-skeleton-loader>
      </h2>
    </div>
  </div>

  <div class="table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && allSelected"
            [indeterminate]="selection.hasValue() && !allSelected">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="data?.clientName" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
          <ngx-skeleton-loader *ngIf="!data.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="user.username">{{user.username}}</span>
          <ngx-skeleton-loader *ngIf="!data?.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>

      <!-- hours worked Column -->
      <ng-container matColumnDef="hoursWorked">
        <th mat-header-cell *matHeaderCellDef> Hours Worked </th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="user.username">{{user.hoursWorked || 0 | number:'1.1-5'}}</span>
          <ngx-skeleton-loader *ngIf="!data?.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>

      <!-- Approval Column -->
      <ng-container matColumnDef="approval">
        <th mat-header-cell *matHeaderCellDef> Approve </th>
        <td mat-cell *matCellDef="let user; let i = dataIndex">
          <button *ngIf="user.username" [disabled]="disabledButtons[i]"  mat-raised-button color="accent" (click)="approve(user)">Approve</button>
          <ngx-skeleton-loader *ngIf="!data?.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>

      <!-- Rejection Column -->
      <ng-container matColumnDef="rejection">
        <th mat-header-cell *matHeaderCellDef> Rejection </th>
        <td mat-cell *matCellDef="let user; let i = dataIndex">
          <button *ngIf="user.username" [disabled]="disabledButtons[i]" mat-raised-button color="warn" (click)="reject(user)">Reject</button>
          <ngx-skeleton-loader *ngIf="!data?.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>


      <!-- Download Report column-->
      <ng-container matColumnDef="downloadReport">
        <th mat-header-cell *matHeaderCellDef>Download Report</th>
        <td mat-cell *matCellDef="let user; let i = dataIndex">
          <button *ngIf="user.username" [disabled]="disabledButtons[i]" mat-raised-button color="secondary" (click)="downloadreport(user)">
              <span *ngIf="isMobile">Download</span>
              <span *ngIf="!isMobile">Download Report</span>
            <mat-spinner *ngIf="user.loading" single-color class="spinner-button" strokeWidth="4" diameter="20"> 
            </mat-spinner>
          </button>
          <ngx-skeleton-loader *ngIf="!data?.clientName" count="1" animation="pulse"></ngx-skeleton-loader>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="selectedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>

        <td *ngIf="data?.approvals?.length === approvedData?.length" class="mat-cell"
          [attr.colspan]="selectedColumns.length">
          All approved! Please click the submit button below
        </td>
        <td *ngIf="data?.approvals?.length === rejectedData?.length" class="mat-cell"
          [attr.colspan]="selectedColumns.length">
          All rejected! Please click the submit button below
        </td>
        <td class="noData" *ngIf="allHandled
                && data?.approvals?.length !== approvedData?.length
                && data?.approvals?.length !== rejectedData?.length" [attr.colspan]="selectedColumns.length">
          <span>All approvals handled! Please click the submit button below</span>
        </td>
      </tr>
      <tr mat-row *matRowDef="let row; columns: selectedColumns;"></tr>
    </table>
  </div>
  <div class="massApprovalSection" *ngIf="data?.clientName && allSelected">
    <button mat-raised-button color="accent" (click)="approve(data?.approvals)">Approve All</button>
    <button mat-raised-button color="warn" (click)="reject(data?.approvals)">Reject All</button>
  </div>
  <div class="massApprovalSection" *ngIf="data?.clientName && multiSelected">
    <button mat-raised-button color="accent" (click)="approve(selection?.selected)">Approve Selected</button>
    <button mat-raised-button color="warn" (click)="reject(selection?.selected)">Reject Selected</button>
  </div>
  <div class="completionSection">
    <mat-card class="approvalCard">
      <mat-card-header>
        <mat-card-title>Approved Timesheets</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p *ngIf="!approvedData || approvedData.length === 0 && data?.clientName">None approved yet</p>
        <ngx-skeleton-loader *ngIf="!data?.clientName" count="5" animation="pulse"></ngx-skeleton-loader>
        <ul *ngIf="approvedData && approvedData.length > 0">
          <li *ngFor="let item of approvedData">{{item.username}} - {{item.hoursWorked}}</li>
        </ul>
      </mat-card-content>

    </mat-card>
    <mat-card class="approvalCard">
      <mat-card-header>
        <mat-card-title>Rejected Timesheets</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul *ngIf="rejectedData && rejectedData.length > 0">
          <li *ngFor="let item of rejectedData">
            {{item.username}} - {{item.hoursWorked}}
            <ul *ngIf="item?.rejectionReason">
              <li>{{item.rejectionReason}}</li>
            </ul>
          </li>
        </ul>
        <ngx-skeleton-loader *ngIf="!data?.clientName" count="5" animation="pulse"></ngx-skeleton-loader>
        <p *ngIf="!rejectedData || rejectedData?.length === 0 && data?.clientName">
          None Rejected Yet
        </p>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="submissionSection">
    <button [class.isMobile]="isMobile" [disabled]="!data?.clientName || !allHandled" mat-raised-button color="primary"
      (click)="submitApproval()">
      Submit Approval
    </button>
  </div>
</div>
<div *ngIf="hasError" class="content">
  <mat-card>
    <mat-card-header>
      <mat-card-title>There was an error with your request</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      {{error}}
    </mat-card-content>
  </mat-card>

</div>
<div *ngIf="approvalComplete" class="content">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Your timesheet approvals are completed</mat-card-title>
    </mat-card-header>
    <mat-card-content>You have submitted your approvals/rejections for this timesheet, and you can <a (click)="window.close()"
        href="#">close</a> this window</mat-card-content>
  </mat-card>
</div>