
import { debounceTime, switchMap, catchError, map } from 'rxjs/operators';
import {
  ActionTypes,
  ApproveAction,
  ApproveSuccessfulAction,
  ApproveFailureAction,
  RejectAction,
  RejectSuccessfulAction,
  RejectFailureAction,
  RemoveLoadingStatusAction
} from './actions';
import { of, Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ApprovalService } from '../services/approval.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ApprovalEffects {
  @Effect()
  approve$: Observable<Action> = this.action$
    .pipe(
      ofType(ActionTypes.APPROVE),
      switchMap((action: ApproveAction) => {
        return this.approvalService.ApproveTimesheet(action.payload).pipe(
          map(res => new ApproveSuccessfulAction(res)),
          catchError((err: HttpErrorResponse) => of(new ApproveFailureAction(err))));
      }));

  @Effect()
  reject$: Observable<Action> = this.action$
    .pipe(
      ofType(ActionTypes.REJECT),
      switchMap((action: RejectAction) => {
        return this.approvalService.RejectTimesheet(action.payload.approval, action.payload.reason).pipe(
          map(res => new RejectSuccessfulAction(res)),
          catchError((err: HttpErrorResponse) => of(new RejectFailureAction(err))));
      }));

  constructor(
    private action$: Actions,
    private approvalService: ApprovalService
  ) { }
}
