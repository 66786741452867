import { Action } from '@ngrx/store';
import { type } from '../util';
import { Approval, Rejection } from 'src/models/Approvals';
import { HttpErrorResponse } from '@angular/common/http';

export const ActionTypes = {
  APPROVE: type('Send report approval to server'),
  APPROVESUCCESSFUL: type('Approval successful'),
  APPROVEFAILURE: type('Rejection failure'),
  REJECT: type('Send report rejection to server'),
  REJECTSUCCESSFUL: type('Rejection successful'),
  REJECTFAILURE: type('Rejection error'),
  REMOVELOADINGSTATUS: type('Removing loading status')
};

export class ApproveAction implements Action {
    type = ActionTypes.APPROVE;

    constructor(public payload: Approval) { }
}

export class ApproveSuccessfulAction implements Action {
    type = ActionTypes.APPROVESUCCESSFUL;
  
    constructor(public payload: Approval) { }
}
  
export class ApproveFailureAction implements Action {
    type = ActionTypes.APPROVEFAILURE;

    constructor(public payload: HttpErrorResponse) { }
}

export class RejectAction implements Action {
    type = ActionTypes.REJECT;
  
    constructor(public payload: Rejection) { }
}

export class RejectSuccessfulAction implements Action {
    type = ActionTypes.REJECTSUCCESSFUL;
  
    constructor(public payload: Approval) { }
}

export class RejectFailureAction implements Action {
    type = ActionTypes.REJECTFAILURE;
  
    constructor(public payload: HttpErrorResponse) { }
}

export class RemoveLoadingStatusAction implements Action {
    type = ActionTypes.REMOVELOADINGSTATUS;
  
    constructor(public payload: any = {}) { }
}

export type Actions =
    ApproveAction |
    ApproveSuccessfulAction |
    ApproveFailureAction |
    RejectAction |
    RejectSuccessfulAction |
    RejectFailureAction |
    RemoveLoadingStatusAction;
