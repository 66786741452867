import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkapprovalComponent } from 'src/app/components/bulkapproval/bulkapproval.component';
import { BulkApprovalRow } from 'src/models/BulkApprovalVM';

@Component({
  selector: 'app-rejectiondialog',
  templateUrl: './rejectiondialog.component.html',
  styleUrls: ['./rejectiondialog.component.scss']
})
export class RejectiondialogComponent implements OnInit {

  public rejectionData: BulkApprovalRow;
  public rejectionList: BulkApprovalRow[];
  public rejectionReason:string;
  constructor(
    private dialogRef: MatDialogRef<RejectiondialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: BulkApprovalRow | BulkApprovalRow[]
  ) {
    if (data instanceof Array) {
      this.rejectionList = <BulkApprovalRow[]>data;
    } else {
      this.rejectionData = <BulkApprovalRow>data;
    }

  }

  ngOnInit(): void {
  }

}
